import React from "react";
import PageWrapper from "../components/PageWrapper";
import Press from "../sections/landing/Press";
import { Helmet } from "react-helmet";

const PressPage = () => {
  return (
    <>
    <Helmet>
        <title>Liputan Media - Ethical Hacker Indonesia</title>  
        <meta name="description" content="Pemberitaan media yang berhubungan dengan Ethical Hacker Indonesia" />
        <meta name="image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta property="og:url" content="https://hack.co.id/press/" />
        <meta property="og:title" content="Liputan Media" />
        <meta property="og:description" content="Pemberitaan media yang berhubungan dengan Ethical Hacker Indonesia" />
        <meta property="og:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@EthicalHackID" />
        <meta name="twitter:title" content="Liputan Media"  />
        <meta name="twitter:description" content="Pemberitaan media yang berhubungan dengan Ethical Hacker Indonesia" />
        <meta name="twitter:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
      </Helmet>
      <PageWrapper
        footerConfig={{
          style: "style1", //style1, style2
        }}
      >
        <Press />
      </PageWrapper>
    </>
  );
};
export default PressPage;
