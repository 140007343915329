import React from "react";

import krasia from "../../assets/image/l5/jpg/krasia-tokped.jpg";
import tirto from "../../assets/image/l5/jpg/tirto-polri.jpg";
import linetoday from "../../assets/image/l5/jpg/linetoday-kp.jpeg";
import cnn from "../../assets/image/l5/jpg/cnn-kp.jpeg";
import techinasia from "../../assets/image/l5/jpg/techinasia-tokped.jpg";
import krasia2 from "../../assets/image/l5/jpg/krasia-attacks.jpg";
import techinasia2 from "../../assets/image/l5/jpg/techinasia-gojek.jpg";
import krasia3 from "../../assets/image/l5/jpg/krasia-indihome.jpg";
import cnn2 from "../../assets/image/l5/jpg/cnn-periksadata.jpeg";

const Press = () => (
  <>
    {/* <!-- CaseStudies section --> */}
    <div class="news-section mt-md-6 pt-24 pt-lg-29">
    <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-6 col-lg-7 col-md-9">
        <div class="section-title text-center mb-11 mb-lg-18">
          <h2 class="title gr-text-2 mb-9 mb-lg-12">Liputan Media</h2>
          <p class="gr-text-8 mb-0">Pemberitaan media yang berhubungan dengan Ethical Hacker Indonesia</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
    <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
          <a href="https://www.cnnindonesia.com/teknologi/20200925074930-185-550741/situs-cek-data-pribadi-yang-bocor" target="_blank" class="card-img">
            <img src={cnn2} alt="" height="215" class="w-100 rounded-top-10" />
          </a>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
            <a href="https://www.cnnindonesia.com/teknologi/20200925074930-185-550741/situs-cek-data-pribadi-yang-bocor" target="_blank">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">CNN Indonesia</p>
            </a>
            <a href="https://www.cnnindonesia.com/teknologi/20200925074930-185-550741/situs-cek-data-pribadi-yang-bocor" target="_blank">
              <h3 class="title text-blackish-blue gr-text-7 mb-0">Periksadata.com : Situs Cek Data Pribadi yang Bocor</h3>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
          <a href="https://kr-asia.com/cybersecurity-expert-exposes-illegal-data-harvesting-of-state-owned-isp-indiehome" target="_blank" class="card-img">
            <img src={krasia3} alt="" height="215" class="w-100 rounded-top-10" />
          </a>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
            <a href="https://kr-asia.com/cybersecurity-expert-exposes-illegal-data-harvesting-of-state-owned-isp-indiehome" target="_blank">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">KrAsia</p>
            </a>
            <a href="https://kr-asia.com/cybersecurity-expert-exposes-illegal-data-harvesting-of-state-owned-isp-indiehome" target="_blank">
              <h3 class="title text-blackish-blue gr-text-7 mb-0">Cybersecurity expert exposes illegal data harvesting of state-owned ISP IndieHome</h3>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
          <a href="https://www.techinasia.com/warning-grab-gojek-utilized-intimidate-activists-indonesia" target="_blank" class="card-img">
            <img src={techinasia2} alt="" height="215" class="w-100 rounded-top-10" />
          </a>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
            <a href="https://www.techinasia.com/warning-grab-gojek-utilized-intimidate-activists-indonesia" target="_blank">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">Techinasia</p>
            </a>
            <a href="https://www.techinasia.com/warning-grab-gojek-utilized-intimidate-activists-indonesia" target="_blank">
              <h3 class="title text-blackish-blue gr-text-7 mb-0">Grab, Gojek used to intimidate activists in Indonesia</h3>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
          <a href="https://kr-asia.com/indonesian-sees-mysterious-escalating-cyber-attacks-in-2020" target="_blank" class="card-img">
            <img src={krasia2} alt="" height="215" class="w-100 rounded-top-10" />
          </a>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
            <a href="https://kr-asia.com/indonesian-sees-mysterious-escalating-cyber-attacks-in-2020" target="_blank">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">KrAsia</p>
            </a>
            <a href="https://kr-asia.com/indonesian-sees-mysterious-escalating-cyber-attacks-in-2020" target="_blank">
              <h3 class="title text-blackish-blue gr-text-7 mb-0">Indonesia sees mysterious, escalating cyber attacks in 2020</h3>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
          <a href="https://www.techinasia.com/tokopedia-rolls-fintech-lending-service-data-breach-investigation" target="_blank" class="card-img">
            <img src={techinasia} alt="" height="215" class="w-100 rounded-top-10" />
          </a>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
            <a href="https://www.techinasia.com/tokopedia-rolls-fintech-lending-service-data-breach-investigation" target="_blank">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">Techinasia</p>
            </a>
            <a href="https://www.techinasia.com/tokopedia-rolls-fintech-lending-service-data-breach-investigation" target="_blank">
              <h3 class="title text-blackish-blue gr-text-7 mb-0">Tokopedia rolls out fintech lending service amid data breach investigation</h3>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
          <a href="https://www.cnnindonesia.com/ekonomi/20200804131347-78-531949/ojk-konfirmasi-kreditplus-soal-dugaan-kebocoran-data-nasabah" target="_blank" class="card-img">
            <img src={cnn} alt="" height="215" class="w-100 rounded-top-10" />
          </a>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
            <a href="https://www.cnnindonesia.com/ekonomi/20200804131347-78-531949/ojk-konfirmasi-kreditplus-soal-dugaan-kebocoran-data-nasabah" target="_blank">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">CNN Indonesia</p>
            </a>
            <a href="https://www.cnnindonesia.com/ekonomi/20200804131347-78-531949/ojk-konfirmasi-kreditplus-soal-dugaan-kebocoran-data-nasabah" target="_blank">
              <h3 class="title text-blackish-blue gr-text-7 mb-0">OJK Konfirmasi Kreditplus Soal Dugaan Kebocoran Data Nasabah</h3>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
          <a href="https://today.line.me/id/v2/article/Pinjaman+Online+KreditPlus+Kebocoran+896+Ribu+Data+Pengguna-a83rDG" target="_blank" class="card-img">
            <img src={linetoday} height="215" alt="" class="w-100 rounded-top-10" />
          </a>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
            <a href="https://today.line.me/id/v2/article/Pinjaman+Online+KreditPlus+Kebocoran+896+Ribu+Data+Pengguna-a83rDG" target="_blank">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">LINE Today</p>
            </a>
            <a href="https://today.line.me/id/v2/article/Pinjaman+Online+KreditPlus+Kebocoran+896+Ribu+Data+Pengguna-a83rDG" target="_blank">
              <h3 class="title text-blackish-blue gr-text-7 mb-0">Pinjaman Online KreditPlus Kebocoran 896 Ribu Data Pengguna</h3>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
          <a href="https://tirto.id/bahaya-di-balik-peretasan-pangkalan-data-anggota-polri-fJzU" target="_blank" class="card-img">
            <img src={tirto} alt="" height="215" class="w-100 rounded-top-10" />
          </a>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
            <a href="https://tirto.id/bahaya-di-balik-peretasan-pangkalan-data-anggota-polri-fJzU" target="_blank">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">Tirto</p>
            </a>
            <a href="https://tirto.id/bahaya-di-balik-peretasan-pangkalan-data-anggota-polri-fJzU" target="_blank">
              <h3 class="title text-blackish-blue gr-text-7 mb-0">Bahaya Di Balik Peretasan Pangkalan Data Anggota Polri</h3>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
          <a href="https://kr-asia.com/what-can-we-learn-from-tokopedias-alleged-91-million-data-leak" target="_blank" class="card-img">
            <img src={krasia} alt="" height="215" class="w-100 rounded-top-10" />
          </a>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
            <a href="https://kr-asia.com/what-can-we-learn-from-tokopedias-alleged-91-million-data-leak" target="_blank">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">KrAsia</p>
            </a>
            <a href="https://kr-asia.com/what-can-we-learn-from-tokopedias-alleged-91-million-data-leak" target="_blank">
              <h3 class="title text-blackish-blue gr-text-7 mb-0">What can we learn from Tokopedia’s alleged 91-million data leak?</h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  </>
);

export default Press;
